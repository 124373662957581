import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { analytics } from './firebase/config';
import { logEvent } from 'firebase/analytics';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NewsletterPopup from './components/NewsletterPopup';
import ClientQuestionnaire from './pages/ClientQuestionnaire';
import './styles/Questionnaire.css';
import AdminDashboard from './pages/AdminDashboard';
import Contract from './pages/Contract';

function App() {
  const location = useLocation();

  useEffect(() => {
    // Track page views
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: location.pathname,
        page_title: document.title
      });
    }
  }, [location]);

  return (
    <AuthProvider>
      <Helmet>
        <title>SlydeTech - Web Development & Data Analytics</title>
        <meta name="description" content="Custom web development and data analytics solutions for businesses of all sizes." />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://slydetech.com/" />
        <meta property="og:title" content="SlydeTech - Data-Driven Solutions for Modern Businesses" />
        <meta property="og:description" content="Transforming businesses with cutting-edge technology solutions and data-driven insights that deliver measurable results and competitive advantages." />
        <meta property="og:image" content="/images/SlydeTech%20-prum%20logo.png" />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://slydetech.com/" />
        <meta property="twitter:title" content="SlydeTech - Data-Driven Solutions for Modern Businesses" />
        <meta property="twitter:description" content="Transforming businesses with cutting-edge technology solutions and data-driven insights that deliver measurable results and competitive advantages." />
        <meta property="twitter:image" content="/images/SlydeTech%20-prum%20logo.png" />
      </Helmet>
      <div className="app-container">
        <Navbar />
        <main>
          <Outlet />
        </main>
        <Footer />
        <NewsletterPopup />
      </div>
    </AuthProvider>
  );
}

export default App; 