import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import ClientQuestionnaire from './pages/ClientQuestionnaire';
import AdminDashboard from './pages/AdminDashboard';
import Contract from './pages/Contract';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/services',
        element: <Services />
      },
      {
        path: '/portfolio',
        element: <Portfolio />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/questionnaire',
        element: <ClientQuestionnaire />
      },
      {
        path: '/contract',
        element: <Contract />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/admin',
        element: (
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        )
      }
    ]
  }
]); 