import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Container, Table, Button, Alert, Modal, Row, Col, Card, Badge, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const AdminDashboard = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch questionnaires
        const q = query(collection(db, 'client_questionnaires'), orderBy('submitted_at', 'desc'));
        const querySnapshot = await getDocs(q);
        const questionnaireData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          submitted_at: doc.data().submitted_at?.toDate().toLocaleString()
        }));
        setQuestionnaires(questionnaireData);

        // Fetch contracts
        const contractsQuery = query(collection(db, 'adminContracts'), orderBy('submittedAt', 'desc'));
        const contractsSnapshot = await getDocs(contractsQuery);
        const contractData = contractsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setContracts(contractData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const downloadQuestionnaire = (questionnaire) => {
    const jsonData = JSON.stringify(questionnaire, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `questionnaire-${questionnaire.id}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleViewDetails = (questionnaire) => {
    setSelectedQuestionnaire(questionnaire);
    setShowDetails(true);
  };

  const renderDetails = (data) => {
    if (!data) return null;

    return (
      <div>
        <h4 className="mb-4">Contact Information</h4>
        <Row className="mb-3">
          <Col md={6}>
            <p><strong>Name:</strong> {data.contactInfo?.name || 'N/A'}</p>
            <p><strong>Email:</strong> {data.contactInfo?.email || 'N/A'}</p>
            <p><strong>Phone:</strong> {data.contactInfo?.phone || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">Project Details</h4>
        <Row className="mb-3">
          <Col md={6}>
            <p><strong>Project Type:</strong> {data.projectDetails?.projectType || data.projectType || 'N/A'}</p>
            <p><strong>Project Description:</strong> {data.projectDetails?.projectDescription || data.projectDescription || 'N/A'}</p>
            <p><strong>Timeline:</strong> {data.projectDetails?.timeline || data.timeline || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">Home Page Content</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Main Headline:</strong> {data.homePage?.mainHeadline || 'N/A'}</p>
            <p><strong>Sub-headline:</strong> {data.homePage?.subHeadline || 'N/A'}</p>
            <p><strong>Key Features:</strong> {data.homePage?.keyFeatures || 'N/A'}</p>
            <p><strong>Call to Action:</strong> {data.homePage?.callToAction || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">About Page Content</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Company Story:</strong> {data.aboutPage?.companyStory || 'N/A'}</p>
            <p><strong>Mission Statement:</strong> {data.aboutPage?.missionStatement || 'N/A'}</p>
            {data.aboutPage?.teamSection === 'yes' && (
              <p><strong>Team Members:</strong> {data.aboutPage?.teamMembers || 'N/A'}</p>
            )}
          </Col>
        </Row>

        <h4 className="mb-4">Services Page Content</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Services List:</strong> {data.servicesPage?.servicesList || 'N/A'}</p>
            <p><strong>Process Description:</strong> {data.servicesPage?.processDescription || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">Additional Information</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Target Audience:</strong> {data.additionalInfo?.targetAudience || 'N/A'}</p>
            <p><strong>Unique Selling Points:</strong> {data.additionalInfo?.uniqueSellingPoints || 'N/A'}</p>
            <p><strong>Project Goals:</strong> {data.additionalInfo?.projectGoals || 'N/A'}</p>
          </Col>
        </Row>
      </div>
    );
  };

  if (loading) {
    return (
      <Container className="mt-5">
        <Alert variant="info">Loading data...</Alert>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="py-5">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-center mb-4">Admin Dashboard</h1>
        <p className="text-center mb-4">Welcome to the SlydeTech admin dashboard. Here you can manage client submissions and contracts.</p>

        {/* Quick Links Section */}
        <Row className="mb-5">
          <Col md={6} className="mb-3">
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-clipboard-question fs-1 me-3"></i>
                    <div>
                      <Card.Title>Client Questionnaire</Card.Title>
                      <Card.Text>Access the client questionnaire form</Card.Text>
                    </div>
                  </div>
                  <Button
                    as={Link}
                    to="/questionnaire"
                    variant="primary"
                    className="mt-auto"
                  >
                    Go to Questionnaire
                  </Button>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
          <Col md={6} className="mb-3">
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-file-earmark-text fs-1 me-3"></i>
                    <div>
                      <Card.Title>Contract Form</Card.Title>
                      <Card.Text>Access the contract form</Card.Text>
                    </div>
                  </div>
                  <Button
                    as={Link}
                    to="/contract"
                    variant="primary"
                    className="mt-auto"
                  >
                    Go to Contract
                  </Button>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        </Row>

        {/* Questionnaires Section */}
        <h2 className="mb-4">Questionnaire Submissions</h2>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Project Type</th>
                <th>Submission Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {questionnaires.map((q) => (
                <tr key={q.id}>
                  <td>{q.contactInfo?.name || 'N/A'}</td>
                  <td>{q.contactInfo?.email || 'N/A'}</td>
                  <td>{q.contactInfo?.phone || 'N/A'}</td>
                  <td>{q.projectDetails?.projectType || q.projectType || 'N/A'}</td>
                  <td>{q.submitted_at || 'N/A'}</td>
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      className="me-2"
                      onClick={() => {
                        setSelectedQuestionnaire(q);
                        setShowQuestionnaireModal(true);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => downloadQuestionnaire(q)}
                    >
                      Download
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {/* Contracts Section */}
        <h2 className="mb-4 mt-5">Contract Submissions</h2>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Total</th>
                <th>Status</th>
                <th>Submission Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {contracts.map((contract) => (
                <tr key={contract.id}>
                  <td>{contract.contactInfo.firstName} {contract.contactInfo.lastName}</td>
                  <td>{contract.contactInfo.email}</td>
                  <td>${contract.total.toFixed(2)}</td>
                  <td>
                    <Badge bg={contract.adminStatus === 'new' ? 'primary' : 'secondary'}>
                      {contract.adminStatus}
                    </Badge>
                  </td>
                  <td>{new Date(contract.submittedAt).toLocaleDateString()}</td>
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      onClick={() => {
                        setSelectedContract(contract);
                        setShowContractModal(true);
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {/* Contract Modal */}
        <Modal
          show={showContractModal}
          onHide={() => setShowContractModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Contract Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedContract && (
              <div>
                <h4>Contact Information</h4>
                <p><strong>Name:</strong> {selectedContract.contactInfo.firstName} {selectedContract.contactInfo.lastName}</p>
                <p><strong>Email:</strong> {selectedContract.contactInfo.email}</p>
                <p><strong>Phone:</strong> {selectedContract.contactInfo.phone}</p>
                <p><strong>Agency:</strong> {selectedContract.contactInfo.agencyName}</p>
                
                <h4 className="mt-4">Address</h4>
                <p>{selectedContract.contactInfo.address.street}</p>
                {selectedContract.contactInfo.address.street2 && (
                  <p>{selectedContract.contactInfo.address.street2}</p>
                )}
                <p>{selectedContract.contactInfo.address.city}, {selectedContract.contactInfo.address.state} {selectedContract.contactInfo.address.zipCode}</p>
                
                <h4 className="mt-4">Services</h4>
                <ul>
                  {Object.entries(selectedContract.services)
                    .filter(([_, service]) => service.selected)
                    .map(([key, service]) => (
                      <li key={key}>
                        {key.replace(/([A-Z])/g, ' $1').trim()} - Quantity: {service.quantity}
                      </li>
                    ))}
                </ul>
                
                {selectedContract.customServices.description && (
                  <>
                    <h4 className="mt-4">Custom Services</h4>
                    <p>{selectedContract.customServices.description}</p>
                    <p><strong>Price:</strong> ${selectedContract.customServices.price.toFixed(2)}</p>
                  </>
                )}
                
                <h4 className="mt-4">Payment Details</h4>
                <p><strong>Total:</strong> ${selectedContract.total.toFixed(2)}</p>
                {selectedContract.couponApplied && (
                  <p><strong>Coupon Applied:</strong> Yes</p>
                )}
                
                <h4 className="mt-4">Signature</h4>
                {selectedContract.signature && (
                  <img
                    src={selectedContract.signature}
                    alt="Signature"
                    style={{ maxWidth: '100%', border: '1px solid #ddd', padding: '10px' }}
                  />
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowContractModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Details Modal */}
        <Modal 
          show={showQuestionnaireModal} 
          onHide={() => setShowQuestionnaireModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-dark">Questionnaire Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {renderDetails(selectedQuestionnaire)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowQuestionnaireModal(false)}>
              Close
            </Button>
            <Button 
              variant="primary" 
              onClick={() => {
                downloadQuestionnaire(selectedQuestionnaire);
                setShowQuestionnaireModal(false);
              }}
            >
              Download
            </Button>
          </Modal.Footer>
        </Modal>
      </motion.div>
    </Container>
  );
};

export default AdminDashboard; 