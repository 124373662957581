import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCKZ6DLLlJSqVPCV8HJZTy1XZWX4OU-jJg",
  authDomain: "slydetechconsulting.firebaseapp.com",
  databaseURL: "https://slydetechconsulting-default-rtdb.firebaseio.com",
  projectId: "slydetechconsulting",
  storageBucket: "slydetechconsulting.firebasestorage.app",
  messagingSenderId: "211689982932",
  appId: "1:211689982932:web:62ae8a5478ea41925c2441",
  measurementId: "G-MKLYL7FLFG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { db, storage, analytics }; 