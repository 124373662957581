import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Container, Row, Col, Form, Button, Accordion } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import SignaturePad from 'react-signature-canvas';

const Contract = () => {
  const [formData, setFormData] = useState({
    date: new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York'
    }),
    contactInfo: {
      firstName: '',
      lastName: '',
      agencyName: '',
      phone: '',
      email: '',
      address: {
        street: '',
        street2: '',
        city: '',
        state: '',
        zipCode: ''
      }
    },
    services: {
      websiteDevelopment: { selected: false, quantity: 1 },
      domainSetup: { selected: false, quantity: 1 },
      websiteMaintenance: { selected: false, quantity: 1 },
      databaseManagement: { selected: false, quantity: 1 },
      serverSetup: { selected: false, quantity: 1 },
      clientPortal: { selected: false, quantity: 1 },
      secureForm: { selected: false, quantity: 1 },
      platformConnection: { selected: false, quantity: 1 },
      ecommerce15: { selected: false, quantity: 1 },
      ecommerce20: { selected: false, quantity: 1 }
    },
    couponCode: '',
    total: 0,
    signature: null,
    agreedToTerms: false,
    customServices: {
      description: '',
      price: 0
    }
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signaturePad, setSignaturePad] = useState(null);

  const servicePrices = {
    websiteDevelopment: 500,
    domainSetup: 12,
    websiteMaintenance: 120,
    databaseManagement: 20,
    serverSetup: 150,
    clientPortal: 100,
    secureForm: 100,
    platformConnection: 200,
    ecommerce15: 200,
    ecommerce20: 250
  };

  const calculateTotal = () => {
    let total = 0;
    Object.entries(formData.services).forEach(([key, value]) => {
      if (value.selected) {
        total += servicePrices[key] * value.quantity;
      }
    });

    // Add custom service price
    total += formData.customServices.price;

    // Apply coupon discount if valid and website development is selected
    if (formData.couponCode === '#500' && formData.services.websiteDevelopment.selected) {
      total = Math.max(0, total - 500); // Ensure total doesn't go below 0
    }

    return total;
  };

  const handleServiceChange = (service, field, value) => {
    setFormData(prev => ({
      ...prev,
      services: {
        ...prev.services,
        [service]: {
          ...prev.services[service],
          [field]: value
        }
      }
    }));
  };

  const handleCouponChange = (e) => {
    const couponCode = e.target.value.toUpperCase();
    setFormData({ ...formData, couponCode });
  };

  const handleClearSignature = () => {
    signaturePad.clear();
    setFormData(prev => ({
      ...prev,
      signature: null
    }));
  };

  const handleSaveSignature = () => {
    if (signaturePad.isEmpty()) {
      setErrors(prev => ({
        ...prev,
        signature: 'Please provide your signature'
      }));
      return;
    }
    setFormData(prev => ({
      ...prev,
      signature: signaturePad.toDataURL()
    }));
  };

  const validateForm = () => {
    const errors = {};
    
    // Contact Information Validation
    if (!formData.contactInfo.firstName.trim()) {
      errors['contactInfo.firstName'] = 'First name is required';
    }
    if (!formData.contactInfo.lastName.trim()) {
      errors['contactInfo.lastName'] = 'Last name is required';
    }
    if (!formData.contactInfo.email.trim()) {
      errors['contactInfo.email'] = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.contactInfo.email)) {
      errors['contactInfo.email'] = 'Invalid email address';
    }
    if (!formData.contactInfo.phone.trim()) {
      errors['contactInfo.phone'] = 'Phone number is required';
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(formData.contactInfo.phone.replace(/\D/g, ''))) {
      errors['contactInfo.phone'] = 'Invalid phone number';
    }

    // Address Validation
    if (!formData.contactInfo.address.street.trim()) {
      errors['contactInfo.address.street'] = 'Street address is required';
    }
    if (!formData.contactInfo.address.city.trim()) {
      errors['contactInfo.address.city'] = 'City is required';
    }
    if (!formData.contactInfo.address.state.trim()) {
      errors['contactInfo.address.state'] = 'State is required';
    }
    if (!formData.contactInfo.address.zipCode.trim()) {
      errors['contactInfo.address.zipCode'] = 'ZIP code is required';
    }

    // Signature Validation
    if (!formData.signature) {
      errors.signature = 'Signature is required';
    }

    // Terms Agreement Validation
    if (!formData.agreedToTerms) {
      errors.agreedToTerms = 'You must agree to the terms';
    }

    // At least one service must be selected
    const hasSelectedService = Object.values(formData.services).some(service => service.selected);
    if (!hasSelectedService && !formData.customServices.description) {
      errors.services = 'At least one service must be selected';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    
    if (isValid) {
      setIsSubmitting(true);
      try {
        console.log('Starting contract submission...');
        const total = calculateTotal();
        console.log('Calculated total:', total);
        
        const contractData = {
          ...formData,
          total,
          submittedAt: new Date().toLocaleString('en-US', {
            timeZone: 'America/New_York'
          }),
          status: 'pending',
          adminNotes: '',
          lastUpdated: new Date().toLocaleString('en-US', {
            timeZone: 'America/New_York'
          })
        };
        
        console.log('Preparing contract data:', contractData);
        
        // Save to contracts collection - this will trigger the Firebase Cloud Function
        console.log('Saving to contracts collection...');
        const contractRef = await addDoc(collection(db, 'contracts'), contractData);
        console.log('Contract saved with ID:', contractRef.id);
        
        const adminContractData = {
          ...contractData,
          contractId: contractRef.id,
          adminStatus: 'new',
          adminViewed: false,
          priority: 'normal',
          assignedTo: '',
          followUpDate: '',
          customServices: formData.customServices,
          couponApplied: formData.couponCode === '#500' && formData.services.websiteDevelopment.selected
        };
        
        // Save to adminContracts collection
        console.log('Saving to adminContracts collection...');
        await addDoc(collection(db, 'adminContracts'), adminContractData);
        console.log('Admin contract saved successfully');
        
        // Show success message
        alert('Contract submitted successfully! A confirmation email has been sent to your email address.');
        
        // Reset form and clear signature pad
        setFormData({
          date: new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York'
          }),
          contactInfo: {
            firstName: '',
            lastName: '',
            agencyName: '',
            phone: '',
            email: '',
            address: {
              street: '',
              street2: '',
              city: '',
              state: '',
              zipCode: ''
            }
          },
          services: {
            websiteDevelopment: { selected: false, quantity: 1 },
            domainSetup: { selected: false, quantity: 1 },
            websiteMaintenance: { selected: false, quantity: 1 },
            databaseManagement: { selected: false, quantity: 1 },
            serverSetup: { selected: false, quantity: 1 },
            clientPortal: { selected: false, quantity: 1 },
            secureForm: { selected: false, quantity: 1 },
            platformConnection: { selected: false, quantity: 1 },
            ecommerce15: { selected: false, quantity: 1 },
            ecommerce20: { selected: false, quantity: 1 }
          },
          couponCode: '',
          total: 0,
          signature: null,
          agreedToTerms: false,
          customServices: {
            description: '',
            price: 0
          }
        });
        
        // Clear the signature pad
        if (signaturePad) {
          signaturePad.clear();
        }
      } catch (error) {
        console.error('Error submitting contract:', error);
        console.error('Error code:', error.code);
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
        
        let errorMessage = 'Error submitting contract. ';
        if (error.code === 'permission-denied') {
          errorMessage += 'You do not have permission to submit this contract.';
        } else if (error.code === 'unavailable') {
          errorMessage += 'The service is currently unavailable. Please try again later.';
        } else {
          errorMessage += 'Please try again. If the problem persists, please contact us directly.';
        }
        
        alert(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Container className="py-5">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-center mb-4">SlydeTech, LLC</h1>
        <h2 className="text-center mb-4">Website Service Plan, Pricing & Order Form</h2>
        <p className="text-center mb-4">
          A simple, done-for-you website solution designed for small businesses, freelancers, and startups.
        </p>

        <Form onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={formData.date}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <h3 className="mb-3">Contact Information</h3>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.firstName}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: { ...formData.contactInfo, firstName: e.target.value }
                  })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Last Name *</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.lastName}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: { ...formData.contactInfo, lastName: e.target.value }
                  })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Agency Name</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.agencyName}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: { ...formData.contactInfo, agencyName: e.target.value }
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Telephone Number *</Form.Label>
                <Form.Control
                  type="tel"
                  value={formData.contactInfo.phone}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: { ...formData.contactInfo, phone: e.target.value }
                  })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  type="email"
                  value={formData.contactInfo.email}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: { ...formData.contactInfo, email: e.target.value }
                  })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <h3 className="mb-3">Address *</h3>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.address.street}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: {
                      ...formData.contactInfo,
                      address: { ...formData.contactInfo.address, street: e.target.value }
                    }
                  })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Street Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.address.street2}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: {
                      ...formData.contactInfo,
                      address: { ...formData.contactInfo.address, street2: e.target.value }
                    }
                  })}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.address.city}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: {
                      ...formData.contactInfo,
                      address: { ...formData.contactInfo.address, city: e.target.value }
                    }
                  })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.address.state}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: {
                      ...formData.contactInfo,
                      address: { ...formData.contactInfo.address, state: e.target.value }
                    }
                  })}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>ZIP Code</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.contactInfo.address.zipCode}
                  onChange={(e) => setFormData({
                    ...formData,
                    contactInfo: {
                      ...formData.contactInfo,
                      address: { ...formData.contactInfo.address, zipCode: e.target.value }
                    }
                  })}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <h3 className="mb-3">Service Type</h3>
          
          {/* Website Development */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="websiteDevelopment"
                label="💻 Website Development - Custom 4-page site (Home, About, Services, Contact) - $500.00"
                checked={formData.services.websiteDevelopment.selected}
                onChange={(e) => handleServiceChange('websiteDevelopment', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.websiteDevelopment.quantity}
                onChange={(e) => handleServiceChange('websiteDevelopment', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.websiteDevelopment.selected}
              />
            </Col>
          </Row>

          {/* Domain Setup */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="domainSetup"
                label="🌐 Domain Setup/Transfer - Your website address (e.g., yourbrand.com) - $12.00"
                checked={formData.services.domainSetup.selected}
                onChange={(e) => handleServiceChange('domainSetup', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.domainSetup.quantity}
                onChange={(e) => handleServiceChange('domainSetup', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.domainSetup.selected}
              />
            </Col>
          </Row>

          {/* Website Maintenance */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="websiteMaintenance"
                label="🛠 Website Maintenance (Hosting + Maintenance) - $120.00/month"
                checked={formData.services.websiteMaintenance.selected}
                onChange={(e) => handleServiceChange('websiteMaintenance', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.websiteMaintenance.quantity}
                onChange={(e) => handleServiceChange('websiteMaintenance', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.websiteMaintenance.selected}
              />
            </Col>
          </Row>

          {/* Database Management */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="databaseManagement"
                label="🗂 Database Management - Stores form data, user logins, dashboard info - $20.00"
                checked={formData.services.databaseManagement.selected}
                onChange={(e) => handleServiceChange('databaseManagement', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.databaseManagement.quantity}
                onChange={(e) => handleServiceChange('databaseManagement', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.databaseManagement.selected}
              />
            </Col>
          </Row>

          {/* Server Setup */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="serverSetup"
                label="🗂 Server Setup & Maintenance - $150.00"
                checked={formData.services.serverSetup.selected}
                onChange={(e) => handleServiceChange('serverSetup', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.serverSetup.quantity}
                onChange={(e) => handleServiceChange('serverSetup', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.serverSetup.selected}
              />
            </Col>
          </Row>

          {/* Client Portal */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="clientPortal"
                label="🔐 Client Portal - Secure login area for document access and updates - $100.00"
                checked={formData.services.clientPortal.selected}
                onChange={(e) => handleServiceChange('clientPortal', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.clientPortal.quantity}
                onChange={(e) => handleServiceChange('clientPortal', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.clientPortal.selected}
              />
            </Col>
          </Row>

          {/* Secure Form */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="secureForm"
                label="🔐 Secure Form - Data encrypted secure form - $100.00"
                checked={formData.services.secureForm.selected}
                onChange={(e) => handleServiceChange('secureForm', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.secureForm.quantity}
                onChange={(e) => handleServiceChange('secureForm', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.secureForm.selected}
              />
            </Col>
          </Row>

          {/* Platform Connection */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="platformConnection"
                label="🔗 Platform Connection - Connect to tools like Google Calendar, Mailchimp, CRMs - $200.00"
                checked={formData.services.platformConnection.selected}
                onChange={(e) => handleServiceChange('platformConnection', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.platformConnection.quantity}
                onChange={(e) => handleServiceChange('platformConnection', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.platformConnection.selected}
              />
            </Col>
          </Row>

          {/* E-commerce 15 */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="ecommerce15"
                label="🛒 E-Commerce Setup (+15 products) - $200.00"
                checked={formData.services.ecommerce15.selected}
                onChange={(e) => handleServiceChange('ecommerce15', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.ecommerce15.quantity}
                onChange={(e) => handleServiceChange('ecommerce15', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.ecommerce15.selected}
              />
            </Col>
          </Row>

          {/* E-commerce 20 */}
          <Row className="mb-3 align-items-center">
            <Col md={8}>
              <Form.Check
                type="checkbox"
                id="ecommerce20"
                label="🛒 E-Commerce Setup (+20 products) - $250.00"
                checked={formData.services.ecommerce20.selected}
                onChange={(e) => handleServiceChange('ecommerce20', 'selected', e.target.checked)}
              />
            </Col>
            <Col md={4}>
              <Form.Control
                type="number"
                min="1"
                value={formData.services.ecommerce20.quantity}
                onChange={(e) => handleServiceChange('ecommerce20', 'quantity', parseInt(e.target.value))}
                disabled={!formData.services.ecommerce20.selected}
              />
            </Col>
          </Row>

          {/* Custom Services */}
          <Row className="mb-3">
            <Col>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="d-flex align-items-center">
                      <span>Custom Services or Add-ons</span>
                      <span className="ms-2 badge bg-warning text-dark">Admin Only</span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="alert alert-info">
                      <i className="bi bi-info-circle me-2"></i>
                      This section is for admin use only. Enter custom services and pricing for the client.
                    </div>
                    <Form.Group className="mb-3">
                      <Form.Label>Service Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter custom service description..."
                        value={formData.customServices.description}
                        onChange={(e) => setFormData({
                          ...formData,
                          customServices: {
                            ...formData.customServices,
                            description: e.target.value
                          }
                        })}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Custom Service Price</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="0.00"
                          value={formData.customServices.price}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value) || 0;
                            setFormData({
                              ...formData,
                              customServices: {
                                ...formData.customServices,
                                price: value
                              }
                            });
                          }}
                        />
                      </div>
                      <Form.Text className="text-muted">
                        Enter the total price for the custom service
                      </Form.Text>
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Enter Coupon Code</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.couponCode}
                  onChange={handleCouponChange}
                  placeholder="Enter coupon code if you have one"
                />
                {formData.couponCode === '#500' && formData.services.websiteDevelopment.selected ? (
                  <div className="text-success mt-2">
                    🎉 Special discount applied!
                  </div>
                ) : formData.couponCode === '#500' && !formData.services.websiteDevelopment.selected ? (
                  <div className="text-warning mt-2">
                    ⚠️ This coupon requires Website Development service
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <div className="mb-4">
            <h4>
              Total: ${calculateTotal().toFixed(2)}
              {formData.couponCode === '#500' && formData.services.websiteDevelopment.selected && (
                <span className="text-success ms-2">
                  (Original: ${(calculateTotal() + 500).toFixed(2)})
                </span>
              )}
            </h4>
          </div>

          <div className="mb-4">
            <h3>SlydeTech Website Policy</h3>
            <div className="policy-content mb-3 p-3 border rounded" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <h4>1. Overview</h4>
              <p>SlydeTech, LLC provides website development, maintenance, and management services to individuals and businesses. This policy outlines the terms and conditions for using these services. All clients must review and acknowledge this policy before services begin.</p>
              
              <h4>2. Scope of Services</h4>
              <p>SlydeTech may offer the following services based on the selected package:</p>
              <ul>
                <li>Website design and development (HTML, React, etc.)</li>
                <li>Website hosting setup assistance</li>
                <li>Monthly maintenance and updates</li>
                <li>Bug fixes and performance enhancements</li>
                <li>Content updates (within agreed scope)</li>
                <li>Technical support during business hours</li>
              </ul>
              <p>Any additional services outside of the agreed scope may incur additional fees.</p>

              <h4>3. Project Timeline</h4>
              <p>Project timelines are estimates and may be subject to change based on various factors including but not limited to:</p>
              <ul>
                <li>Client responsiveness and feedback</li>
                <li>Scope changes</li>
                <li>Technical challenges</li>
                <li>Third-party dependencies</li>
              </ul>

              <h4>4. Payment Terms</h4>
              <p>Payment terms are as follows:</p>
              <ul>
                <li>50% deposit required to begin work</li>
                <li>25% due upon completion of design phase</li>
                <li>25% due upon project completion</li>
                <li>All payments are non-refundable</li>
                <li>Late payments may incur additional fees</li>
              </ul>

              <h4>5. Intellectual Property</h4>
              <p>Upon full payment, you will receive ownership of the final website and its components, excluding:</p>
              <ul>
                <li>Third-party components and licenses</li>
                <li>Proprietary tools and frameworks</li>
                <li>Source code of custom-built tools</li>
                <li>Any pre-existing SlydeTech intellectual property</li>
              </ul>

              <h4>6. Content and Materials</h4>
              <p>You are responsible for:</p>
              <ul>
                <li>Providing all necessary content and materials in a timely manner</li>
                <li>Ensuring all content is legally owned or licensed</li>
                <li>Obtaining necessary permissions for third-party content</li>
                <li>Providing content in the agreed format and specifications</li>
              </ul>

              <h4>7. Revisions and Changes</h4>
              <p>Our service includes:</p>
              <ul>
                <li>A reasonable number of revisions during the design phase</li>
                <li>Two rounds of revisions after initial development</li>
                <li>Additional revisions may incur extra charges</li>
                <li>Major scope changes may require a new agreement</li>
              </ul>

              <h4>8. Hosting and Maintenance</h4>
              <p>Hosting and maintenance services include:</p>
              <ul>
                <li>Server space and bandwidth allocation</li>
                <li>Regular security updates</li>
                <li>Backup services</li>
                <li>Performance monitoring</li>
                <li>Technical support during business hours</li>
              </ul>

              <h4>9. Support and Updates</h4>
              <p>Post-launch support includes:</p>
              <ul>
                <li>30 days of free support for launch-related issues</li>
                <li>Ongoing support available through maintenance packages</li>
                <li>Emergency support may incur additional charges</li>
                <li>Response times vary based on support package</li>
              </ul>

              <h4>10. Termination</h4>
              <p>Either party may terminate the project with written notice. Upon termination:</p>
              <ul>
                <li>You will be billed for work completed</li>
                <li>We will deliver all completed work</li>
                <li>Any deposits or payments made are non-refundable</li>
                <li>You must settle any outstanding payments</li>
              </ul>

              <h4>11. Limitation of Liability</h4>
              <p>Our liability is limited to:</p>
              <ul>
                <li>The amount paid for the services</li>
                <li>Direct damages caused by our negligence</li>
                <li>We are not liable for indirect, incidental, or consequential damages</li>
                <li>We are not liable for third-party service issues</li>
              </ul>

              <h4>12. Confidentiality</h4>
              <p>We agree to:</p>
              <ul>
                <li>Keep all client information confidential</li>
                <li>Not disclose sensitive business information</li>
                <li>Use client data only for project purposes</li>
                <li>Implement appropriate security measures</li>
              </ul>

              <h4>13. Governing Law</h4>
              <p>This Policy is governed by the laws of the state in which SlydeTech is registered. Any disputes will be resolved through arbitration in our jurisdiction.</p>
            </div>

            <Form.Check
              type="checkbox"
              id="agreedToTerms"
              label="I have read and agree to the SlydeTech Website Policy"
              checked={formData.agreedToTerms}
              onChange={(e) => setFormData({ ...formData, agreedToTerms: e.target.checked })}
              required
            />
          </div>

          <h3 className="mb-4 mt-4">Signature</h3>
          <div className="border rounded p-3 mb-3">
            <div style={{ 
              border: '1px solid #ccc', 
              backgroundColor: 'white',
              width: '400px',
              height: '150px',
              position: 'relative',
              overflow: 'hidden',
              margin: '0 auto'
            }}>
              <SignaturePad
                ref={ref => setSignaturePad(ref)}
                canvasProps={{
                  className: 'signature-canvas',
                  style: {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    touchAction: 'none',
                    cursor: 'crosshair',
                    backgroundColor: 'white'
                  }
                }}
                penColor="black"
                clearOnResize={false}
                velocityFilterWeight={0.7}
                minWidth={1.5}
                maxWidth={2.5}
              />
            </div>
            <div className="d-flex gap-2 mt-3 justify-content-center">
              <Button
                variant="outline-secondary"
                onClick={handleClearSignature}
              >
                Clear
              </Button>
              <Button
                variant="primary"
                onClick={handleSaveSignature}
              >
                Save Signature
              </Button>
            </div>
            {errors.signature && (
              <div className="text-danger mt-2 text-center">{errors.signature}</div>
            )}
          </div>

          {formData.signature && (
            <div className="mb-4">
              <h4>Signature Preview</h4>
              <img
                src={formData.signature}
                alt="Signature"
                className="img-fluid border rounded"
                style={{ maxHeight: '100px' }}
              />
            </div>
          )}

          <Button
            type="submit"
            variant="primary"
            size="lg"
            disabled={isSubmitting}
            className="w-100"
          >
            {isSubmitting ? 'Submitting...' : 'Submit Contract'}
          </Button>
        </Form>
      </motion.div>
    </Container>
  );
};

export default Contract; 