import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';

const ClientQuestionnaire = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  
  const [formData, setFormData] = useState({
    // Home Page
    homePage: {
      mainHeadline: '',
      subHeadline: '',
      keyFeatures: '',
      callToAction: '',
      heroImagePreference: '',
    },
    
    // About Page
    aboutPage: {
      companyStory: '',
      missionStatement: '',
      teamSection: 'no',
      teamMembers: '',
      achievements: '',
    },
    
    // Services Page
    servicesPage: {
      servicesList: '',
      servicePricing: 'no',
      serviceDetails: '',
      processDescription: '',
      serviceImages: 'no',
    },
    
    // Contact Page
    contactPage: {
      contactFormFields: [],
      businessHours: '',
      locationInfo: '',
      socialMediaLinks: '',
      mapPreference: 'no',
      streetAddress: '',
      suite: '',
      city: '',
      state: '',
      zipCode: '',
      displayAddress: '',
    },
    
    // General Design
    design: {
      colorPreferences: '',
      websiteExamples: '',
      existingLogo: 'no',
      brandAssets: '',
    },
    
    // Contact Information
    contactInfo: {
      name: '',
      email: '',
      phone: '',
      preferredContact: 'email',
    },
    
    // Additional Information
    additionalInfo: {
      targetAudience: '',
      competitors: '',
      uniqueSellingPoints: '',
      brandPersonality: '',
      projectGoals: '',
      contentStatus: '',
      existingMaterials: '',
      specialFeatures: '',
      additionalNotes: '',
      attachments: []
    },
    projectType: '',
    projectDescription: '',
    timeline: '',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');
    
    // Special handling for phone number
    if (name === 'contactInfo.phone') {
      const formattedPhone = formatPhone(value);
      setFormData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: formattedPhone
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value
        }
      }));
    }

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const nextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    // Remove all non-digit characters
    const digitsOnly = phone.replace(/\D/g, '');
    // Check if it's 10 digits (US phone number)
    return digitsOnly.length === 10;
  };

  const formatPhone = (value) => {
    // Remove all non-digit characters
    const digitsOnly = value.replace(/\D/g, '');
    
    // Format as (XXX) XXX-XXXX
    if (digitsOnly.length <= 3) {
      return digitsOnly;
    }
    if (digitsOnly.length <= 6) {
      return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
    }
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Contact Information Validation
    if (!formData.contactInfo?.name?.trim()) {
      newErrors['contactInfo.name'] = 'Name is required';
      isValid = false;
    }
    if (!formData.contactInfo?.email?.trim()) {
      newErrors['contactInfo.email'] = 'Email is required';
      isValid = false;
    } else if (!validateEmail(formData.contactInfo.email)) {
      newErrors['contactInfo.email'] = 'Please enter a valid email address';
      isValid = false;
    }
    if (!formData.contactInfo?.phone?.trim()) {
      newErrors['contactInfo.phone'] = 'Phone number is required';
      isValid = false;
    } else if (!validatePhone(formData.contactInfo.phone)) {
      newErrors['contactInfo.phone'] = 'Please enter a valid 10-digit phone number';
      isValid = false;
    }

    // Home Page Validation
    if (!formData.homePage?.mainHeadline?.trim()) {
      newErrors['homePage.mainHeadline'] = 'Main headline is required';
      isValid = false;
    }
    if (!formData.homePage?.keyFeatures?.trim()) {
      newErrors['homePage.keyFeatures'] = 'Key features are required';
      isValid = false;
    }

    // About Page Validation
    if (!formData.aboutPage?.companyStory?.trim()) {
      newErrors['aboutPage.companyStory'] = 'Company story is required';
      isValid = false;
    }
    if (!formData.aboutPage?.missionStatement?.trim()) {
      newErrors['aboutPage.missionStatement'] = 'Mission statement is required';
      isValid = false;
    }

    // Services Page Validation
    if (!formData.servicesPage?.servicesList?.trim()) {
      newErrors['servicesPage.servicesList'] = 'List of services is required';
      isValid = false;
    }

    // Contact Page Validation
    if (!formData.contactPage?.streetAddress?.trim()) {
      newErrors['contactPage.streetAddress'] = 'Street address is required';
      isValid = false;
    }
    if (!formData.contactPage?.city?.trim()) {
      newErrors['contactPage.city'] = 'City is required';
      isValid = false;
    }
    if (!formData.contactPage?.state?.trim()) {
      newErrors['contactPage.state'] = 'State is required';
      isValid = false;
    }
    if (!formData.contactPage?.zipCode?.trim()) {
      newErrors['contactPage.zipCode'] = 'ZIP code is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submit button clicked');
    
    // Validate the form first
    const isValid = validateForm();
    if (!isValid) {
      setSubmitStatus({
        type: 'error',
        message: 'Please fill in all required fields correctly before submitting.'
      });
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      // Log the data being sent
      console.log('Submitting questionnaire data:', formData);

      // Add the document to Firestore
      const docRef = await addDoc(collection(db, 'client_questionnaires'), {
        ...formData,
        submitted_at: serverTimestamp()
      });

      console.log('Document written with ID: ', docRef.id);
      
      setSubmitStatus({
        type: 'success',
        message: 'Thank you! Your questionnaire has been submitted successfully. We\'ll review your requirements and get back to you soon.'
      });
      
      // Reset form with initial state
      setFormData({
        homePage: {
          mainHeadline: '',
          subHeadline: '',
          keyFeatures: '',
          callToAction: '',
          heroImagePreference: '',
        },
        aboutPage: {
          companyStory: '',
          missionStatement: '',
          teamSection: 'no',
          teamMembers: '',
          achievements: '',
        },
        servicesPage: {
          servicesList: '',
          servicePricing: 'no',
          serviceDetails: '',
          processDescription: '',
          serviceImages: 'no',
        },
        contactPage: {
          contactFormFields: [],
          businessHours: '',
          locationInfo: '',
          socialMediaLinks: '',
          mapPreference: 'no',
          streetAddress: '',
          suite: '',
          city: '',
          state: '',
          zipCode: '',
          displayAddress: '',
        },
        design: {
          colorPreferences: '',
          websiteExamples: '',
          existingLogo: 'no',
          brandAssets: '',
        },
        contactInfo: {
          name: '',
          email: '',
          phone: '',
          preferredContact: 'email',
        },
        additionalInfo: {
          targetAudience: '',
          competitors: '',
          uniqueSellingPoints: '',
          brandPersonality: '',
          projectGoals: '',
          contentStatus: '',
          existingMaterials: '',
          specialFeatures: '',
          additionalNotes: '',
          attachments: []
        },
        projectType: '',
        projectDescription: '',
        timeline: '',
      });
      setCurrentStep(1);
      
      setTimeout(() => {
        navigate('/thank-you');
      }, 2000);
    } catch (error) {
      console.error('Error submitting questionnaire:', error);
      console.error('Error details:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      
      setSubmitStatus({
        type: 'error',
        message: `There was an error submitting your questionnaire: ${error.message}. Please try again or contact us directly.`
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    setFormData(prev => ({
      ...prev,
      additionalInfo: {
        ...prev.additionalInfo,
        attachments: [...prev.additionalInfo.attachments, ...files]
      }
    }));
  };

  const handleFileRemove = (index) => {
    setFormData(prev => ({
      ...prev,
      additionalInfo: {
        ...prev.additionalInfo,
        attachments: prev.additionalInfo.attachments.filter((_, i) => i !== index)
      }
    }));
  };

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <h3 className="text-dark mb-4">Home Page Details</h3>
            <div className="mb-3">
              <label className="form-label">Main Headline/Message <span className="text-danger">*</span></label>
              <textarea
                className={`form-control ${errors['homePage.mainHeadline'] ? 'is-invalid' : ''}`}
                name="homePage.mainHeadline"
                value={formData.homePage.mainHeadline}
                onChange={handleInputChange}
                placeholder="What's the main message you want visitors to see first?"
                rows="2"
              ></textarea>
              {errors['homePage.mainHeadline'] && (
                <div className="invalid-feedback">{errors['homePage.mainHeadline']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Sub-headline/Supporting Message</label>
              <textarea
                className={`form-control ${errors['homePage.subHeadline'] ? 'is-invalid' : ''}`}
                name="homePage.subHeadline"
                value={formData.homePage.subHeadline}
                onChange={handleInputChange}
                placeholder="What supporting message would you like to include?"
                rows="2"
              ></textarea>
              {errors['homePage.subHeadline'] && (
                <div className="invalid-feedback">{errors['homePage.subHeadline']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Key Features/Benefits to Highlight <span className="text-danger">*</span></label>
              <textarea
                className={`form-control ${errors['homePage.keyFeatures'] ? 'is-invalid' : ''}`}
                name="homePage.keyFeatures"
                value={formData.homePage.keyFeatures}
                onChange={handleInputChange}
                placeholder="List the main features or benefits you want to showcase"
                rows="3"
              ></textarea>
              {errors['homePage.keyFeatures'] && (
                <div className="invalid-feedback">{errors['homePage.keyFeatures']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Primary Call to Action</label>
              <input
                type="text"
                className={`form-control ${errors['homePage.callToAction'] ? 'is-invalid' : ''}`}
                name="homePage.callToAction"
                value={formData.homePage.callToAction}
                onChange={handleInputChange}
                placeholder="E.g., 'Book Now', 'Get Started', 'Contact Us'"
              />
              {errors['homePage.callToAction'] && (
                <div className="invalid-feedback">{errors['homePage.callToAction']}</div>
              )}
            </div>
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <h3 className="text-dark mb-4">About Page Content</h3>
            <div className="mb-3">
              <label className="form-label">Company Story <span className="text-danger">*</span></label>
              <textarea
                className={`form-control ${errors['aboutPage.companyStory'] ? 'is-invalid' : ''}`}
                name="aboutPage.companyStory"
                value={formData.aboutPage.companyStory}
                onChange={handleInputChange}
                placeholder="Share your company's history and background"
                rows="3"
              ></textarea>
              {errors['aboutPage.companyStory'] && (
                <div className="invalid-feedback">{errors['aboutPage.companyStory']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Mission Statement <span className="text-danger">*</span></label>
              <textarea
                className={`form-control ${errors['aboutPage.missionStatement'] ? 'is-invalid' : ''}`}
                name="aboutPage.missionStatement"
                value={formData.aboutPage.missionStatement}
                onChange={handleInputChange}
                placeholder="What's your company's mission or purpose?"
                rows="2"
              ></textarea>
              {errors['aboutPage.missionStatement'] && (
                <div className="invalid-feedback">{errors['aboutPage.missionStatement']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Include Team Section?</label>
              <select
                className={`form-select ${errors['aboutPage.teamSection'] ? 'is-invalid' : ''}`}
                name="aboutPage.teamSection"
                value={formData.aboutPage.teamSection}
                onChange={handleInputChange}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
              {errors['aboutPage.teamSection'] && (
                <div className="invalid-feedback">{errors['aboutPage.teamSection']}</div>
              )}
            </div>
            {formData.aboutPage.teamSection === 'yes' && (
              <div className="mb-3">
                <label className="form-label">Team Member Details</label>
                <textarea
                  className={`form-control ${errors['aboutPage.teamMembers'] ? 'is-invalid' : ''}`}
                  name="aboutPage.teamMembers"
                  value={formData.aboutPage.teamMembers}
                  onChange={handleInputChange}
                  placeholder="List team members and their roles"
                  rows="3"
                ></textarea>
                {errors['aboutPage.teamMembers'] && (
                  <div className="invalid-feedback">{errors['aboutPage.teamMembers']}</div>
                )}
              </div>
            )}
          </motion.div>
        );

      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <h3 className="text-dark mb-4">Services Page Details</h3>
            <div className="mb-3">
              <label className="form-label">List of Services <span className="text-danger">*</span></label>
              <textarea
                className={`form-control ${errors['servicesPage.servicesList'] ? 'is-invalid' : ''}`}
                name="servicesPage.servicesList"
                value={formData.servicesPage.servicesList}
                onChange={handleInputChange}
                placeholder="List your main services and a brief description of each"
                rows="4"
              ></textarea>
              {errors['servicesPage.servicesList'] && (
                <div className="invalid-feedback">{errors['servicesPage.servicesList']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Include Pricing?</label>
              <select
                className={`form-select ${errors['servicesPage.servicePricing'] ? 'is-invalid' : ''}`}
                name="servicesPage.servicePricing"
                value={formData.servicesPage.servicePricing}
                onChange={handleInputChange}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
                <option value="contact">Contact for Pricing</option>
              </select>
              {errors['servicesPage.servicePricing'] && (
                <div className="invalid-feedback">{errors['servicesPage.servicePricing']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Service Process/How It Works</label>
              <textarea
                className={`form-control ${errors['servicesPage.processDescription'] ? 'is-invalid' : ''}`}
                name="servicesPage.processDescription"
                value={formData.servicesPage.processDescription}
                onChange={handleInputChange}
                placeholder="Describe your service process or how you work with clients"
                rows="3"
              ></textarea>
              {errors['servicesPage.processDescription'] && (
                <div className="invalid-feedback">{errors['servicesPage.processDescription']}</div>
              )}
            </div>
          </motion.div>
        );

      case 4:
        return (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <h3 className="text-dark mb-4">Contact Page & Design Preferences</h3>
            <div className="mb-3">
              <label className="form-label">Contact Form Fields Needed</label>
              <div className="form-check">
                {['Name', 'Email', 'Phone', 'Company', 'Message', 'Service Interest', 'Budget Range'].map((field) => (
                  <div key={field}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={field}
                      name="contactPage.contactFormFields"
                      value={field}
                      checked={formData.contactPage.contactFormFields.includes(field)}
                      onChange={(e) => {
                        const fields = e.target.checked 
                          ? [...formData.contactPage.contactFormFields, field]
                          : formData.contactPage.contactFormFields.filter(f => f !== field);
                        setFormData({
                          ...formData,
                          contactPage: {
                            ...formData.contactPage,
                            contactFormFields: fields
                          }
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor={field}>{field}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Business Hours</label>
              <textarea
                className={`form-control ${errors['contactPage.businessHours'] ? 'is-invalid' : ''}`}
                name="contactPage.businessHours"
                value={formData.contactPage.businessHours}
                onChange={handleInputChange}
                placeholder="List your business hours"
                rows="2"
              ></textarea>
              {errors['contactPage.businessHours'] && (
                <div className="invalid-feedback">{errors['contactPage.businessHours']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Include Map?</label>
              <select
                className={`form-select ${errors['contactPage.mapPreference'] ? 'is-invalid' : ''}`}
                name="contactPage.mapPreference"
                value={formData.contactPage.mapPreference}
                onChange={handleInputChange}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
              {errors['contactPage.mapPreference'] && (
                <div className="invalid-feedback">{errors['contactPage.mapPreference']}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Business Address <span className="text-danger">*</span></label>
              <input
                type="text"
                className={`form-control mb-2 ${errors['contactPage.streetAddress'] ? 'is-invalid' : ''}`}
                name="contactPage.streetAddress"
                value={formData.contactPage.streetAddress}
                onChange={handleInputChange}
                placeholder="Street Address"
              />
              {errors['contactPage.streetAddress'] && (
                <div className="invalid-feedback">{errors['contactPage.streetAddress']}</div>
              )}
              <input
                type="text"
                className="form-control mb-2"
                name="contactPage.suite"
                value={formData.contactPage.suite}
                onChange={handleInputChange}
                placeholder="Suite/Unit # (optional)"
              />
              <div className="row">
                <div className="col-md-6 mb-2">
                  <input
                    type="text"
                    className={`form-control ${errors['contactPage.city'] ? 'is-invalid' : ''}`}
                    name="contactPage.city"
                    value={formData.contactPage.city}
                    onChange={handleInputChange}
                    placeholder="City"
                  />
                  {errors['contactPage.city'] && (
                    <div className="invalid-feedback">{errors['contactPage.city']}</div>
                  )}
                </div>
                <div className="col-md-3 mb-2">
                  <input
                    type="text"
                    className={`form-control ${errors['contactPage.state'] ? 'is-invalid' : ''}`}
                    name="contactPage.state"
                    value={formData.contactPage.state}
                    onChange={handleInputChange}
                    placeholder="State"
                  />
                  {errors['contactPage.state'] && (
                    <div className="invalid-feedback">{errors['contactPage.state']}</div>
                  )}
                </div>
                <div className="col-md-3 mb-2">
                  <input
                    type="text"
                    className={`form-control ${errors['contactPage.zipCode'] ? 'is-invalid' : ''}`}
                    name="contactPage.zipCode"
                    value={formData.contactPage.zipCode}
                    onChange={handleInputChange}
                    placeholder="ZIP Code"
                  />
                  {errors['contactPage.zipCode'] && (
                    <div className="invalid-feedback">{errors['contactPage.zipCode']}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Would you like this address displayed on the website?</label>
              <select
                className="form-select"
                name="contactPage.displayAddress"
                value={formData.contactPage.displayAddress}
                onChange={handleInputChange}
              >
                <option value="">Select an option</option>
                <option value="full">Yes, show full address</option>
                <option value="partial">Show only city/state</option>
                <option value="no">Don't display address</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="form-label">Your Contact Information</label>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="contactInfo.name"
                  value={formData.contactInfo.name}
                  onChange={handleInputChange}
                  isInvalid={!!errors['contactInfo.name']}
                />
                <Form.Control.Feedback type="invalid">
                  {errors['contactInfo.name']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="contactInfo.email"
                  value={formData.contactInfo.email}
                  onChange={handleInputChange}
                  isInvalid={!!errors['contactInfo.email']}
                />
                <Form.Control.Feedback type="invalid">
                  {errors['contactInfo.email']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="contactInfo.phone"
                  value={formData.contactInfo.phone}
                  onChange={handleInputChange}
                  isInvalid={!!errors['contactInfo.phone']}
                />
                <Form.Control.Feedback type="invalid">
                  {errors['contactInfo.phone']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Preferred Contact Method</Form.Label>
                <Form.Select
                  name="contactInfo.preferredContact"
                  value={formData.contactInfo.preferredContact}
                  onChange={handleInputChange}
                >
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                  <option value="text">Text Message</option>
                </Form.Select>
              </Form.Group>
            </div>
          </motion.div>
        );

      case 5:
        return (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
          >
            <h3 className="text-dark mb-4">Additional Information</h3>
            <div className="mb-3">
              <label className="form-label">Who is your target audience?</label>
              <textarea
                className={`form-control ${errors['additionalInfo.targetAudience'] ? 'is-invalid' : ''}`}
                name="additionalInfo.targetAudience"
                value={formData.additionalInfo.targetAudience}
                onChange={handleInputChange}
                placeholder="Age range, demographics, interests, etc."
                rows="2"
              ></textarea>
              {errors['additionalInfo.targetAudience'] && (
                <div className="invalid-feedback">{errors['additionalInfo.targetAudience']}</div>
              )}
            </div>
            
            <div className="mb-3">
              <label className="form-label">Who are your main competitors?</label>
              <textarea
                className="form-control"
                name="additionalInfo.competitors"
                value={formData.additionalInfo.competitors}
                onChange={handleInputChange}
                placeholder="List your main competitors and their websites if possible"
                rows="2"
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">What makes your business unique?</label>
              <textarea
                className={`form-control ${errors['additionalInfo.uniqueSellingPoints'] ? 'is-invalid' : ''}`}
                name="additionalInfo.uniqueSellingPoints"
                value={formData.additionalInfo.uniqueSellingPoints}
                onChange={handleInputChange}
                placeholder="What sets you apart from your competitors?"
                rows="2"
              ></textarea>
              {errors['additionalInfo.uniqueSellingPoints'] && (
                <div className="invalid-feedback">{errors['additionalInfo.uniqueSellingPoints']}</div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">How would you describe your brand personality?</label>
              <textarea
                className="form-control"
                name="additionalInfo.brandPersonality"
                value={formData.additionalInfo.brandPersonality}
                onChange={handleInputChange}
                placeholder="E.g., Professional, Friendly, Luxurious, Innovative, etc."
                rows="2"
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">What are your main goals for this website?</label>
              <textarea
                className={`form-control ${errors['additionalInfo.projectGoals'] ? 'is-invalid' : ''}`}
                name="additionalInfo.projectGoals"
                value={formData.additionalInfo.projectGoals}
                onChange={handleInputChange}
                placeholder="E.g., Generate leads, Increase sales, Build brand awareness, etc."
                rows="2"
              ></textarea>
              {errors['additionalInfo.projectGoals'] && (
                <div className="invalid-feedback">{errors['additionalInfo.projectGoals']}</div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">What is the status of your content?</label>
              <select
                className="form-select"
                name="additionalInfo.contentStatus"
                value={formData.additionalInfo.contentStatus}
                onChange={handleInputChange}
              >
                <option value="">Select an option</option>
                <option value="ready">We have all content ready</option>
                <option value="partial">We have some content, need help with the rest</option>
                <option value="none">We need help creating all content</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Do you have existing materials?</label>
              <textarea
                className="form-control"
                name="additionalInfo.existingMaterials"
                value={formData.additionalInfo.existingMaterials}
                onChange={handleInputChange}
                placeholder="E.g., Logo, Photos, Videos, Brochures, etc."
                rows="2"
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">Upload Supporting Materials</label>
              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileUpload}
                  multiple
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
                />
              </div>
              {formData.additionalInfo.attachments.length > 0 && (
                <div className="mt-2">
                  <p className="mb-1">Attached files:</p>
                  <ul className="list-group">
                    {formData.additionalInfo.attachments.map((file, index) => (
                      <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        {file.name}
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => handleFileRemove(index)}
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Any special features needed?</label>
              <textarea
                className="form-control"
                name="additionalInfo.specialFeatures"
                value={formData.additionalInfo.specialFeatures}
                onChange={handleInputChange}
                placeholder="E.g., Newsletter signup, Blog, Social media feed, etc."
                rows="2"
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">Additional Notes or Requirements</label>
              <textarea
                className="form-control"
                name="additionalInfo.additionalNotes"
                value={formData.additionalInfo.additionalNotes}
                onChange={handleInputChange}
                placeholder="Any other information you'd like to share that would help us understand your needs better"
                rows="4"
              ></textarea>
            </div>
          </motion.div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <PageHeader
        title="Project Questionnaire"
        subtitle="Help us understand your vision"
        backgroundImage="url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&w=1600&q=80')"
      />

      <div className="container mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card shadow-lg">
              <div className="card-body p-4">
                {submitStatus && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={`alert alert-${submitStatus.type} mb-4`}
                  >
                    {submitStatus.message}
                  </motion.div>
                )}
                <form onSubmit={handleSubmit}>
                  {/* Progress indicator */}
                  <div className="progress mb-4">
                    <div 
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: `${(currentStep / 5) * 100}%` }}
                      aria-valuenow={(currentStep / 5) * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  
                  {/* Step content */}
                  <div className="step-content">
                    {renderStep()}
                  </div>
                  
                  {/* Navigation buttons */}
                  <div className="d-flex justify-content-between mt-4">
                    {currentStep > 1 && (
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={prevStep}
                      >
                        Previous
                      </button>
                    )}
                    {currentStep < 5 ? (
                      <button
                        type="button"
                        className="btn btn-primary ms-auto"
                        onClick={nextStep}
                      >
                        Next
                      </button>
                    ) : (
                      <div className="d-flex align-items-center gap-3">
                        {Object.keys(errors).length > 0 && (
                          <span className="text-danger">
                            Please fill in all required fields
                          </span>
                        )}
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Submitting...' : 'Submit Questionnaire'}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientQuestionnaire; 